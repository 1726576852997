<script>

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { required } from "vuelidate/lib/validators";
import { financial } from '@/api/misc'
import { getAgentStatementApi } from '@/api/agent/statement'

/**
 * Agent Basic Info component
 */


//import DefaultAvatar from '@/assets/images/users/avatar-1.jpg'

export default {



    props: ['data'],

    components: {
        flatPickr
    },

    validations: {
        start_date: { required },
        end_date: { required },
    },

    data() {

        return {
            agent: {
                statistic: {
                    deal_count: 0,
                    fee_total: 0,
                    lisitng_count: 0,
                    award: 0,
                    balance: 0,
                    commission_ytd: 0,
                }
            },
            fields: [
                { key: 'Date' },
                { key: 'Type' },
                { key: 'Invoice' },
                { key: 'Amount' },
                { key: 'Trans' }
            ],
            list: [],
            start_date: '',
            end_date: '',
            total_amount : 0,
        }

    },
    watch: {
        data(n) {
            this.agent = n
        }
    },


    methods: {

        f(v, d = 2) {
            return financial(v, d)
        },

        query_statement() {

            let data = {
                agent_id: this.data.id,
                start_date: this.start_date,
                end_date: this.end_date
            }
            getAgentStatementApi().query_agent_statement(data).then(res => {
                if (res.errCode == 0) {
                    this.list = res.data
                }
            })
        },



        getFootValue(item) {

            if (item.label == 'Amount') {
                return '$' + this.total_amount.toLocaleString()
            } else if (item.label == 'Post Date') {
                return 'Total: '
            } else {
                return ''
            }

        },


    },

    created() {

    },

    mounted() {

    }

};
</script>


<template>
    <div class="row">
        <form class="needs-validation" @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-2">
                    <div class="mb-3  form-floating">
                        <flat-pickr v-model="start_date" placeholder="Select a date" class="form-control"
                            id="start_date" :class="{ 'is-invalid': $v.start_date.$error }"></flat-pickr>
                        <div v-if="$v.start_date.$error" class="invalid-feedback">
                            <span v-if="!$v.start_date.required">This value is required.</span>
                        </div>
                        <label class="form-label" for="start_date">Start Date</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3  form-floating">
                        <flat-pickr v-model="end_date" placeholder="Select a date" class="form-control" id="end_date"
                            :class="{ 'is-invalid': $v.end_date.$error }"></flat-pickr>
                        <label class="form-label" for="end_date">End Date</label>
                        <div v-if="$v.end_date.$error" class="invalid-feedback">
                            <span v-if="!$v.end_date.required">This value is required.</span>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="mb-3">
                        <b-button variant="primary" @click="query_statement">Query</b-button>
                    </div>
                </div>
            </div>

        </form>

        <div class="table-responsive">
            <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="list.length"
                    class="table-check datatables" >



                    <template #cell(Date)="data">
                        {{ data.item.post_date }}
                    </template>
                    <template #cell(Type)="data">
                        {{ data.item.cash_type }}
                    </template>
                    <template #cell(Invoice)="data">
                        <router-link
                            :to="{ name: 'ar-agent-bill-detail', query: { invoice_number: data.item.invoice_number, view: true } }">
                            {{ data.item.invoice }}
                        </router-link>
                    </template>
                    <template #cell(Amount)="data">
                        ${{ f(data.item.amount).toLocaleString() }}
                    </template>

                    <template #cell(Trans)="data">
                        {{ data.item.transaction_code }}
                    </template>



                </b-table>
            </div>
        </div>
        <!-- end table responsive -->
    </div>
</template>