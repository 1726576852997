<script>




export default {


    props: ['data'],
    components: {

    },


    data() {

        return {
            disabled: true,
            agent : {
                first_name : '',
                last_name : '',


                key_date : {

                },
                fees: {},
                fields: {
                    tax : {
                        first_name : '',
                        last_name : '',
                        is_prec : 0,
                    },
                    basic : {
                        city : ''
                    },

                    financial : {
                        is_defer : 0,
                        is_gst_hold : 0,
                    }
                },
                bill: {items : []}
            }
        }

    },


    methods: {

    },

    created() {

    },

    mounted() {

    },

    watch: {
        data(n) {
            console.log(n)
            this.agent = n
        }
    },

};
</script>


<template>
    <div class="row">
        <div class="card-body">
            <div class="row">

                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    01
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Basic Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">First Name</label>
                                        <input type="text" class="form-control" :disabled="disabled" v-model="agent.first_name" />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                        <input type="text" class="form-control" :disabled="disabled"
                                            v-model="agent.last_name" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-designation-input">Legal Given Name</label>
                                        <input type="text" class="form-control" :disabled="disabled"
                                            v-model="agent.legal_name" />
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                               

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">CODE</label>
                                        <input type="text" class="form-control" v-model="agent.LWA_userName"
                                            :disabled="disabled" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">PM Code</label>
                                        <input type="text" class="form-control" v-model="agent.property_mgt_code"
                                            :disabled="disabled" />
                                    </div>
                                </div>


                               
                               

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">Board License V#</label>
                                        <input type="text" class="form-control" v-model="agent.code"
                                            :disabled="disabled" />
                                    </div>
                                </div>


                            </div>


                            <div class="row">
                               

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label for="choices-office-input" class="form-label">REMAX ID</label>
                                        <input type="text" class="form-control" v-model="agent.fields.basic.remax"
                                            :disabled="disabled" />
                                    </div>
                                </div>


                               <div class="col-lg-2">
                                   <div class="mb-3">
                                       <label class="form-label" for="gen-info-name-input">RE/MAX Start Date:</label>
                                       <input type="text" class="form-control" v-model="agent.fields.basic.remax_start_date"
                                           :disabled="disabled" />
                                   </div>
                               </div>
                            </div>

                            <div class="row">
                               

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label for="choices-office-input" class="form-label">BCFSA</label>
                                        <input type="text" class="form-control"  v-model="agent.fields.basic.bcfsa"
                                            :disabled="disabled" />
                                    </div>
                                </div>


                              <div class="col-lg-2">
                                  <div class="mb-3">
                                      <label class="form-label" for="gen-info-name-input">BCFSA Start Date:</label>
                                      <input type="text" class="form-control" v-model="agent.fields.basic.bcfsa_start_date"
                                          :disabled="disabled" />
                                  </div>
                              </div>

                              <div class="col-lg-2">
                                  <div class="mb-3">
                                      <label class="form-label" for="gen-info-name-input">BCFSA End Date:</label>
                                      <input type="text" class="form-control" v-model="agent.fields.basic.bcfsa_end_date"
                                          :disabled="disabled" />
                                  </div>
                              </div>

                           </div>


                        </div>
                    </div>
                </div>
                <!-- end card -->

                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    02
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Personel Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-email-input">Email :</label>
                                    <input type="email" class="form-control" v-model="agent.email"
                                        id="contact-info-email-input" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">Phone :</label>
                                    <input type="text" class="form-control" v-model="agent.phone" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-md-0">
                                    <label for="contact-info-birthday-input" class="form-label">Birthday</label>
                                    <input type="email" class="form-control" v-model="agent.birthday"
                                        :disabled="disabled" />
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-1">
                                <div class="mb-md-0">
                                    <label for="contact-info-birthday-input" class="form-label">Unit</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.unit" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-md-0">
                                    <label  class="form-label">Street Address</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.street" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-md-0">
                                    <label  class="form-label">City</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.city" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-md-0">
                                    <label  class="form-label">Province</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.province" :disabled="disabled" maxlength="2"/>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-md-0">
                                    <label  class="form-label">Postal Code</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.postal_code" :disabled="disabled" maxlength="7" v-mask="'NNN NNN'" />
                                </div>
                            </div>
                        </div>

                     

                    </div>
                </div>
                <!-- end card -->


                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    03
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Tax Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">First Name</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.first_name"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                    <input type="text" class="form-control" :disabled="disabled"
                                        v-model="agent.fields.tax.last_name" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">GST #</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.gst_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">SIN/ITN</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.sin_number"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-1">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Unit</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.unit"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Address</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.street"
                                        :disabled="disabled" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">City</label>
                                    <input type="text" class="form-control" v-model="data.fields.tax.city"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Province</label>
                                    <input type="text" class="form-control" id="gen-info-designation-input"
                                        :disabled="disabled" v-model="agent.fields.tax.province" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Postal Code</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.postal_code"
                                        :disabled="disabled" />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <!-- end card -->


                <!-- Financial Info-->
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    04
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Financial Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Billing Payment Type</label>
                                    <b-form-select class="form-control" v-model="agent.charge_type" :options="[{text: 'Cheque', value: 'CHEQUE'},{ text: 'PAD', value: 'PAD'},{text: 'Credit Card', value: 'CREDIT_CARD'},{ text: 'E-Transfer', value: 'ETRANSFER'}]" :disabled="true"/>
                                </div>
                            </div>
                            <div class="col-lg-1" v-if="agent.charge_type == 'CREDIT_CARD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Card Type</label>
                                    <input type="text" class="form-control" :disabled="disabled"
                                        v-model="agent.fields.financial.charge_credit_card_type" />
                                </div>
                            </div>

                            <div class="col-lg-2" v-if="agent.charge_type == 'CREDIT_CARD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Name on Card</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_credit_card_name"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="agent.charge_type == 'CREDIT_CARD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Card Number</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_credit_card_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-1" v-if="agent.charge_type == 'CREDIT_CARD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Card CVC</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_credit_card_cvc"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-1" v-if="agent.charge_type == 'CREDIT_CARD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Expiry Date</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_credit_card_expiry_date"
                                        :disabled="disabled" />
                                </div>
                            </div>



                            <div class="col-lg-2" v-if="agent.charge_type == 'PAD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Institution</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_institute_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="agent.charge_type == 'PAD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Transit</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_transit_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-1" v-if="agent.charge_type == 'PAD'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Account</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.charge_account_number"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Deposit Type for Agent Commission</label>
                                    <b-form-select class="form-control" v-model="agent.payment_type" :options="[{text: 'Cheque', value: 'CHEQUE'},{ text: 'Direct Deposit', value: 'EFT'}]" :disabled="true"/>
                                </div>
                            </div>

                            <div class="col-lg-2" v-if="agent.payment_type == 'EFT'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Institution</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.pay_institute_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="agent.payment_type == 'EFT'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">transit</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.pay_transit_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="agent.payment_type == 'EFT'">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Account</label>
                                    <input type="text" class="form-control" v-model="agent.fields.financial.pay_account_number"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-2">
                                <label class="form-label">Commission Defer</label>
                                <b-form-select class="form-control" v-model="agent.fields.financial.is_defer" :options="[{text : 'Yes', value : '1'},{text : 'No', value : '0'}]"  :disabled="disabled"></b-form-select>
                            </div>

                            <div class="col-md-2">
                                <label class="form-label">GST Hold</label>
                                <b-form-select class="form-control" v-model="agent.fields.financial.is_gst_hold" :options="[{text : 'Yes', value : '1'},{text : 'No', value : '0'}]"  :disabled="disabled"></b-form-select>
                            </div>
                        </div>
                        
                    </div>
                   

                </div>
                <!-- end card -->

                <!-- PREC Info -->
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    04
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">PREC Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="mt-4">
                                    <label class="form-label" for="gen-info-designation-input">Is PREC</label>
                                    <input type="checkbox" class="form-check-control" :disabled="disabled" />
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-licence-number-input">PREC Name :</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.prec_name"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">ITN :</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.prec_itn"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-birthday-input" class="form-label">PREC Date</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax.prec_date"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- end card -->


                <!-- Team Info -->
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    05
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Team Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row" v-if="agent.team_flag == '0' || agent.team_flag == undefined">
                            <div class="col-lg-4">
                                <div class="mt-4">
                                    <label class="form-label" for="gen-info-designation-input">No Team</label>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="agent.team_flag == '1' || agent.team_flag =='2'">

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-licence-number-input">Team :</label>
                                    <input type="text" class="form-control"  :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">Start Date :</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.team_start_date"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-birthday-input" class="form-label">End Date</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.team_end_date"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- end card -->




                <!-- Other Info -->
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    06
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Other Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                       
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">Copier #:</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.copier"  :disabled="disabled"/>
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">FOB #:</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.fob" :disabled="disabled" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input"> Computer Username:</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.computer_login_name" :disabled="disabled" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">Login Password:</label>
                                    <input type="text" class="form-control" v-model="agent.fields.basic.computer_password" :disabled="disabled" />
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                <!-- end card -->

            </div>
        </div>
        <!-- end card body -->


    </div>
    <!-- end row -->
</template>