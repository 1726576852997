
import request from '@/utils/request';




class AgentStatementApi {
   

    query_agent_statement = (data) => {
        return request({
            url: '/adm/brokerage/agent/statement/query_agent_cash_flow',
            method: 'post',
            data
        });	
    }
    

    
}



let stApi = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const getAgentStatementApi = () => {


    if (!stApi) {
        stApi = new AgentStatementApi();
    }

    return stApi;
}



export { getAgentStatementApi };


